<template>
  <div class="filter">
    <div
      v-if="needSearchItems || (scroll?.needDisplaySearch.value && !hideSearch)"
      class="filter__search"
    >
      <TextField
        v-model="searchText"
        icon-path="navigation/search"
        :placeholder="props.searchPlaceholder || 'Поиск'"
        size="small"
        :disabled="disabled"
        :clearable="searchClearable"
        :icon-disabled="searchIconDisabled"
      />
    </div>

    <div
      v-if="loadingBlockIsDisplayed"
      class="filter-loading"
    >
      <IndicatorProgress
        dark
        class="filter-loading__indicator"
      />

      <span
        v-if="props.loadingText"
        class="filter-loading__text"
      >
        {{ props.loadingText || 'Идет поиск...' }}
      </span>
    </div>

    <div
      v-else-if="emptyBlockIsDisplayed"
      class="filter__empty"
    >
      {{ props.emptyText || 'Нет результатов' }}
    </div>

    <template v-else-if="displayItems.length">
      <div
        ref="listRef"
        class="filter__list"
        :class="{ scroll: scroll.displayScroll.value, full: fullView }"
      >
        <Checkbox
          v-if="isChosenAll"
          v-model="chosenAll"
          :disabled="disabled"
          :class="{
            'filter__list__item': true,
            'filter__list__item--choose-all': needSearch,
          }"
        >
          Выбрать все
        </Checkbox>

        <Checkbox
          v-for="option in displayItems"
          :key="option.id"
          class="filter__list__item"
          :disabled="option.disabledCheckbox"
          :model-value="option.checked"
          @update:model-value="onUpdate($event, option.id)"
        >
          {{ option?.name }} <span class="filter__list__item-count">{{ option.count }}</span> <span
            v-if="option.description"
            class="filter__list__item-description"
          >
            <br> {{ option.description }}
          </span>
        </Checkbox>
      </div>

      <button
        v-if="scroll.numberHiddenItems.value"
        class="btn btn-text"
        :disabled="disabled"
        @click="onDisplayMoreItems"
      >
        Показать еще {{ scroll.numberHiddenItems.value }}
      </button>
    </template>
  </div>
</template>

<script lang="ts" setup>
interface _VTI_TYPE_CSSProperties {
    /**
     * The index signature was removed to enable closed typing for style
     * using CSSType. You're able to use type assertion or module augmentation
     * to add properties or an index signature of your own.
     *
     * For examples and more information, visit:
     * https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
     */
    [v: `--${string}`]: string | number | undefined;
}
type _VTI_TYPE_StyleValue = false | null | undefined | string | _VTI_TYPE_CSSProperties | _VTI_TYPE_Array
type _VTI_TYPE_EPopperPlacement = /* enum */ string
type _VTI_TYPE_EFilterTypes = /* enum */ string
interface _VTI_TYPE_IFilterEnum {
  id: string;
  name: string;
  type: _VTI_TYPE_EFilterTypes;
  description?: string;
  dropdownPosition?: _VTI_TYPE_EPopperPlacement;
  purpose?: _VTI_TYPE_Array;
  IsInvalid?: boolean;
  baseFilterProps?: _VTI_TYPE_Record;
  popupStyle?: _VTI_TYPE_StyleValue;
  popupClass?: _VTI_TYPE_Record | string;
  submitButtonStyle?: _VTI_TYPE_StyleValue;
  options: _VTI_TYPE_Array;
  needSearch?: boolean;
  requiredCount?: number;
}
interface IFilterEnumProps {
  needSearch?: boolean;
  hideSearch?: boolean;
  disabled?: boolean;
  filter: _VTI_TYPE_IFilterEnum;
  modelValue: _VTI_TYPE_IFilterEnum;
  fullView?: boolean;
  isChosenAll?: boolean;
  onSearchEnumOptions?(searchText: string, filter: IFilterEnum): Promise<void> | void;
  loadingText?: string;
  emptyText?: string;
  searching?: boolean;
  searchPlaceholder?: string;
  searchClearable?: boolean;
  searchIconDisabled?: boolean;
}
import { IFilterEnum, IFilterEnumOption } from '../../models/filters.model'
import Checkbox from 'shared/components/Checkbox.vue'
import TextField from 'shared/components/TextField.vue'
import { cloneDeep } from 'lodash-es'
import { WatchSubscription } from '../../utils/watchSubscription'
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch'
import IndicatorProgress from '../IndicatorProgress.vue'
import { usePerfectScrollbar } from '../../composables/usePerfectScrollbar'
const props = defineProps<IFilterEnumProps>();
const emit = defineEmits<{
  (e: 'update:modelValue', filter: IFilterEnum): void;
}>();
const watchSubscription = new WatchSubscription();
const internalFilter = ref<IFilterEnum>(cloneDeep(props.modelValue || props.filter));
const listRef = ref<HTMLDivElement | undefined>();
const searchText = ref('');
const scroll = useScroll(0, props.fullView ? (props.modelValue || props.filter)?.options?.length : undefined);
const perfectScrollbar = usePerfectScrollbar(listRef?.value, {
  scrollYMarginOffset: 10,
});
const selectedOptions = computed(
  () => internalFilter.value.options.filter((option) => option.checked) || [],
);
const preparedOptions = computed(
  () => (internalFilter.value.options || []).map((option) => ({
    ...option,
    disabledCheckbox: props.disabled
      || (internalFilter.value.requiredCount
        && internalFilter.value.requiredCount >= selectedOptions.value.length
        && option.checked
      ),
  })),
);
const sortedOptions = computed(
  () => preparedOptions.value
    .sort((optionA, optionB) => optionA.name > optionB.name
      ? -1
      : optionA.name < optionB.name
        ? 1
        : 0,
    )
    .sort(
      (optionA, optionB) => optionA.checked === optionB.checked
        ? 0
        : optionA.checked > optionB.checked
          ? -1
          : 1,
    ),
);
const needSearchItems = computed(
  () => props.filter.needSearch || props.needSearch,
);
const externalSearch = computed(
  () => needSearchItems && props.onSearchEnumOptions,
);
const loadingBlockIsDisplayed = computed(
  () => externalSearch.value && props.searching,
);
const emptyBlockIsDisplayed = computed(
  () => externalSearch.value && props.emptyText,
);
const displayItems = computed<Array<IFilterEnumOption>>(() => {
  if (searchText.value && !externalSearch.value) {
    scroll.onDisplayMoreItems();
    return preparedOptions.value.filter(
      (option) => option?.name
        .toLowerCase()
        .includes(searchText.value.toLowerCase()),
    );
  }
  return props.fullView
    ? sortedOptions.value
    : [...sortedOptions.value].slice(0, scroll.numberDisplayItems.value);
});
const chosenAll = computed({
  get: () => !!preparedOptions.value.every((option) => option.checked),
  set: (newValue) => onChosenAllUpdate(newValue),
});
function onChosenAllUpdate(event: boolean): void {
  internalFilter.value?.options.forEach((option) => option.checked = event);
  emitUpdate(internalFilter.value);
}
function onUpdate(event: boolean, id: number): void {
  const foundOption = internalFilter.value?.options.find((option) => option.id === id);
  if (!foundOption) {
    return;
  }
  foundOption.checked = event;
  emitUpdate(internalFilter.value);
}
function emitUpdate(filter: IFilterEnum): void {
  emit('update:modelValue', filter);
}
function setInternalFilter(newFilter: IFilterEnum): void {
  internalFilter.value = cloneDeep(newFilter);
  scroll.setNumberItems(newFilter.options?.length);
  perfectScrollbar.update(listRef.value);
}
function onDisplayMoreItems(): void {
  scroll.onDisplayMoreItems();
  perfectScrollbar.update(listRef.value);
}
watchSubscription.add(
  watch(
    () => props.modelValue,
    (newModelValue) => setInternalFilter(newModelValue || props.filter),
    { immediate: true },
  ),
  watch(
    searchText,
    (value) => !value && scroll.onResetState(),
  ),
  watch(
    () => internalFilter.value.options,
    (newInternalFilterOptions) => {
      scroll.setNumberItems(newInternalFilterOptions?.length);
      perfectScrollbar.update(listRef.value);
    },
    { deep: true },
  ),
  watch(
    searchText,
    async (newSearchText) => externalSearch.value
      && internalFilter.value
      && await props.onSearchEnumOptions?.(newSearchText, internalFilter.value),
  ),
);
useSSRUnsubscribeWatch(watchSubscription);
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.filter {
  &__search {
    padding: 0;
    margin: 0;

    & :deep(.mm-input--is-small) {
      padding-bottom: 0;
    }

    :deep(.mm-input__input-container) {
      height: 40px;
    }
  }

  &-loading {
    display: flex;
    align-items: center;
    column-gap: 12px;

    &__indicator {
      width: 20px;
      height: 20px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $text-light-green;
    }
  }

  &__empty {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text-dark-green;
  }

  &-loading,
  &__empty,
  &__list {
    margin-top: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    min-height: 25px;

    .ps {
      width: 100%;
      min-height: 25px;
    }

    &__item {
      margin-bottom: 12px;
      display: flex;
      align-items: flex-start;

      @media (-webkit-device-pixel-ratio: 1.25) {
        align-items: center;
      }

      :deep(span.content),
      :deep(span.light) {
        display: inline-block;

        &::first-line {
          line-height: 24px;
        }
      }

      :deep(.checkbox__input) {
        @media (-webkit-device-pixel-ratio: 1.25) {
          width: 23px;
          height: 23px;

          & > span::after {
            height: 10px;
            left: 9px;
            top: 5px;
            width: 5px;
          }
        }
      }

      &--choose-all {
        margin-top: 16px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__item-count {
      margin-left: 12px;
      color: $filter-count-color;
    }

    &__item-description {
      color: $text-light-green;
    }

    &.scroll {
      max-height: 348px;
      overflow-y: auto;
    }

    .full {
      height: 100%;
    }
  }

  & > .btn {
    margin-top: 20px;
    padding: 0;
  }
}
</style>
